<template>
  <div class="container">
    <el-row style="background-color: #ffffff">
      <content-title :nav="nav"></content-title>
    </el-row>
     <categoryList></categoryList>
  </div>
</template>
  
  <script>
import categoryList from "./components/categoryList.vue";

export default {
  components: {
    categoryList,
  },
  data() {
    return {
      nav: { firstNav: "类目管理中心", secondNav: "类目管理" },
    };
  },
  mounted() {
      
  },
  methods: {
    
  },
};
</script>
  
  <style lang="less">
</style>
  